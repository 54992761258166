import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import HomepageBookBlock from '../../components/HomepageBookBlock';
import { CategoriesBlock } from './categoryBlock';
import EmailSignup from '../../components/EmailSignup';
import { makeRequest } from '../../Utils';
import Hero from './hero';
import BlogBlock from './blogblock';
import PickList from './staffpicks';
import LazyWrapper from '../../components/LazyWrapper';

const tempBlocks = [
  {
    type: 'categories'
  }
]

export default function Homepage({ data }) {
  const [homepage, setHomepage] = useState(data ? data : { blocks: [] });
  const [posts, setPosts] = useState([]);
  document.title = 'Asterism Books | Online Bookstore and Wholesale for Independent Publishers';

  const getData = async () => {
    let data = await makeRequest('options?id=homepage');
    window.localStorage.setItem('welcomeSeen', true);
    setHomepage(data);
    let postData = await makeRequest(`posts?per=4`);
    setPosts(postData);
  }

  useEffect(() => {
    if (!data) {
      getData();
    }
  }, []);

  const parent = useRef();

  return (
    <div ref={parent}>
      <>
        <Hero post={posts[0] ?? {}} />
        {homepage.blocks.map((block, index) => {
          if (block.type === 'products') {
            return (<LazyWrapper container={parent}>
              <HomepageBookBlock block={index} title={block.title} source={block.source} sourceId={block.sourceId} moreText={block.moreText} moreLink={block.moreLink} />
              {index === 1 && <div className='mobileShow roomBelowLarge'><EmailSignup /></div>}
            </LazyWrapper>)
          } else if (block.type === 'categories') {
            return (<CategoriesBlock index={index} />)
          } else if (block.type === 'blog') {
            return (<LazyWrapper container={parent}><BlogBlock posts={posts} /></LazyWrapper >)
          } else if (block.type === 'staffPicks') {
            return (<LazyWrapper container={parent}><PickList block={block} /></LazyWrapper>)
          } else {
            return (<></>)
          }
        })}
      </>
    </div >
  )
}

