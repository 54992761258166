
import React, { useState, useEffect } from 'react';
import { useInView } from "react-intersection-observer";


export default function LazyWrapper({ children, tail = false, height = '600px', container = null }) {
  const [show, setShow] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: `${document.body.offsetHeight + 300}px 0px`,
    triggerOnce: true,
    root: document
  });

  useEffect(() => {
    if (inView && !show) {
      setShow(true);
    }
  }, [inView, entry]);

  return (<div style={{ height: show ? 'auto' : height }} ref={ref}>{show && children}</div>)
}