import React, { useState, useEffect } from 'react';
import './style.css';
import { randomIntFromInterval } from '../../Utils';

export default function GhostProductListItem({ smaller }) {
  return (
    <div className={`productContainer ${smaller && 'productContainer_smaller'}`}>
      <div className='productItem'>
        {!smaller && <div className='productItem_head'>
          <div className='productDate'><GhostText height={13} /></div>
          <div className='productIcons'>
          </div>
        </div>}
        <div className={`productItem__image`}>
          <GhostImage />
        </div>
        <div className='productItem__info'>
          <div className='productItem__left'>
            <div className='productItem__publisher'>
              <GhostText height={13} />
            </div>
            <div className='productItem__title'>
              <GhostText height={22} rows={2} />
            </div>
            <div className='productItem__authors'><GhostText height={17} /></div>
          </div>
          <div className='productItem__right'>
            <div className='productItemPrice'>
              <div className='productItemPrice__retail'>
                <GhostText height={22} />
              </div>
            </div>
          </div>
        </div>
        <div className='productItem__bottom'>
          <div>
          </div>
          <div className='productItem__controls'>
          </div>
        </div>
      </div>
    </div>
  )
}

export function GhostText({ height, rows = 1, min, max }) {
  const [width, setWidth] = useState('auto');
  const [margin, setMargin] = useState('0');

  const getDynamicWidth = (index) => {
    const isLast = index === rows - 1;
    const percentage = min && max ? randomIntFromInterval(min, max) : isLast ? randomIntFromInterval(64, 98) : randomIntFromInterval(88, 96);
    return percentage + '%';
  }

  const getMarginBelow = () => {
    if (rows === 1) return '0';
    let size = parseInt(height);
    let line = size * 1.4;
    let margin = line - size;
    return margin + 'px';
  }

  return (
    <div className='ghostTextContainer'>
      {Array.from({ length: rows }, (_, index) => {
        return <TextLine key={index} height={height} margin={margin} min={min} max={max} isLast={index === rows - 1} />
      })}
    </div>
  )
}

function TextLine({ height, min, max, isLast }) {
  const [width, setWidth] = useState('auto');
  const [margin, setMargin] = useState('0');
  const getDynamicWidth = () => {
    const percentage = min && max ? randomIntFromInterval(min, max) : isLast ? randomIntFromInterval(64, 98) : randomIntFromInterval(88, 96);
    setWidth(percentage + '%');
  }

  const getMargin = () => {
    let size = parseInt(height);
    let line = size * 1.4;
    let margin = (line - size) / 2;
    setMargin(`${margin}px 0`);
  }

  useEffect(() => {
    getDynamicWidth()
    getMargin();
  }, [])

  return (
    <div className='shimmer' style={{ width: width, height: height, margin: margin }}></div>
  )
}

export function GhostImage({ width = '180px' }) {
  return (
    <div className='ghostTextContainer'>
      <div className='shimmer ghostImage' style={{ width: width, aspectRatio: '11 / 17' }}></div>
    </div >
  )
}