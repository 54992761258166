import React, { useState, useEffect, useRef } from 'react';
import { asaEvent, makeRequest } from '../../Utils';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts'
import { useRecoilValue } from 'recoil';
import { pageviewState, sessionState, userState } from '../../atoms';
import BookIcon from '../../icons/book';
import './style.css';

export default function Search({ classList = '' }) {
  const [query, setQuery] = useState('');
  const [hits, setHits] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);

  const navigate = useNavigate();

  const location = useLocation();

  const showOff = () => {
    setTimeout(() => {
      setShowResults(false)
    }, 300)
  }

  const getResults = async (term) => {
    let data = await makeRequest(`search?term=${term}&mode=quick`);
    setHits(data);
  }

  const prevQuery = useRef();

  const shouldSearch = (string) => {
    return (string.length > 4 || !string.toLowerCase().includes('the')) && string.length > 2
  }

  useEffect(() => {
    let previous = prevQuery?.current || '';
    if (!shouldSearch(query) && hits.length > 0) {
      setHits([]);
    } else if (shouldSearch(query) && query !== previous) {
      getResults(query)
    }
    prevQuery.current = query;
  }, [query, hits]);

  useEffect(() => {
    setQuery('');
  }, [location])

  return (
    <>
      <div className={`searchBar ${classList} ${showResults && 'searchBarActive'}`}>
        <input
          onFocus={() => { setShowResults(true) }}
          aria-label={'Search all products'}
          onBlur={showOff}
          className='searchBar__input'
          placeholder={'Search by title, author, translator, or ISBN'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate(`/search?query=${query}`)
            }
          }}
        />
        <button className='searchBar__button' onClick={() => {
          navigate(`/search?query=${query}`)
        }} aria-label='Search'><i className='fa-solid fa-magnifying-glass'></i></button>
        <div className='searchResultsMain'>
          {showResults && query.length > 3 &&
            <div className='searchResult'>
              <Link to={`/search?query=${query}`} className='searchResultLink'>
                <span className='searchResultIcon'><i className='fa-solid fa-magnifying-glass'></i></span>
                Search for <strong>{query}...</strong></Link>
            </div>
          }
          {showResults && hits.map((hit) =>
            <Link key={hit._id} className='searchResult searchResultLink' to={`/product/${hit.slug}`} onClick={() => {
              asaEvent({
                session: session.id,
                category: 'search',
                action: 'productClick',
                label: hit._id,
                locationOne: '',
                locationTwo: '',
                locationThree: 'searchResults',
                testgroup: session.group,
                pagetype: pageview.pagetype,
                pageview: pageview.id,
                campaign: session.campaign,
              });
            }}>
              <div>
                <span className='searchResultIcon'>
                  <BookIcon width={'12px'} color='dark' /></span>
                <strong>{hit.title}</strong>
                {hit.authors && <span> - </span>}
                <span>{hit.authors && hit.authors.join(', ')}</span>
              </div>
            </Link>
          )}
        </div>
      </div>
      {showResults && hits.length > 0 && <div className='resultsScreen' onClick={() => { setShowResults(false) }}></div>}
    </>
  )
}

export function SearchControl({ current, selected, term = '', addNew, text = 'Add', classList = '', searchref = null, eagerSendId = false, label = null, disabled = false }) {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 200);
  const [hits, setHits] = useState([]);
  const [showResults, setShowResults] = useState(true);

  useEffect(() => {
    setQuery('');
  }, [term])

  const getSearchResults = async () => {
    let data = await makeRequest(`all-search?term=${query}`);
    setHits(data);
  }

  const showOff = () => {
    setTimeout(() => {
      setShowResults(false)
    }, 420)
  }

  const isInList = (id) => {
    let isSelected = false;
    selected.forEach((item) => {
      if (item.id === id) {
        isSelected = true;
        return;
      }
    });
    return isSelected;
  }

  let isbn10 = new RegExp(/[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9|X|x]/);

  let isbn13 = new RegExp(/[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9|X|x][0-9][0-9][0-9]/);

  useEffect(() => {
    if ((isbn10.test(query.replaceAll('-', '')) || isbn13.test(query.replaceAll('-', ''))) && eagerSendId) {
      addNew(query.replaceAll('-', ''));
      return;
    }
    if (query.length < 4) {
      setHits([]);
    } else {
      getSearchResults();
    }
  }, [debouncedQuery]);


  return (
    <div className={`searchControl ${classList}`}>
      {label && <label className='inputControl__label'>{label}</label>}

      <input ref={searchref} onFocus={() => { setShowResults(true) }} onBlur={showOff} className='searchControl__input' placeholder={'Search by title, author, publisher, translator, or ISBN'} disabled={disabled} value={query} onChange={(e) => setQuery(e.target.value)} />
      <div className='searchResults'>
        {showResults && hits.map((hit) =>
          <div className={`searchResultRow searchResult quickHit ${(isInList(hit._id) || current === hit._id) && 'searchResult-disabled'}`} key={hit._id} onClick={() => {
            if (!isInList(hit._id) && current !== hit._id) {
              addNew(hit._id);
            }
          }}>
            <div className='searchImage-smaller'>
              {hit.cover && hit.cover['300'] && <img alt={hit.title} src={hit.cover['300'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} />}
            </div>
            <div>
              <div className='searchResult__title'>{hit.title}</div>
              <div className='searchResult__authors'>{hit.authors && hit.authors.join(', ')}</div>
              <div className='searchResult__authors'>{hit.format}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}


export function PublisherSearchControl({ current, selected, sendData = false, term = '', addNew, text = 'Add', classList = '', searchref = null, eagerSendId = false, label = null, disabled = false }) {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [hits, setHits] = useState([]);
  const [showResults, setShowResults] = useState(true);
  const user = useRecoilValue(userState);

  useEffect(() => {
    setQuery('');
  }, [term])

  const getSearchResults = async () => {
    let data = await makeRequest(`pub-search?term=${query}&pub=${user.id}`);
    setHits(data);
  }

  const showOff = () => {
    setTimeout(() => {
      setShowResults(false)
    }, 420)
  }

  const isInList = (id) => {
    let isSelected = false;
    selected.forEach((item) => {
      if (item.id === id) {
        isSelected = true;
        return;
      }
    });
    return isSelected;
  }

  let isbn10 = new RegExp(/[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9|X|x]/);

  let isbn13 = new RegExp(/[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9|X|x][0-9][0-9][0-9]/);

  useEffect(() => {
    if ((isbn10.test(query.replaceAll('-', '')) || isbn13.test(query.replaceAll('-', ''))) && eagerSendId) {
      addNew(query.replaceAll('-', ''));
      return;
    }
    if (query.length < 4) {
      setHits([]);
    } else {
      getSearchResults();
    }
  }, [debouncedQuery]);


  return (
    <div className={`searchControl ${classList}`}>
      {label && <label className='inputControl__label'>{label}</label>}

      <input ref={searchref} onFocus={() => { setShowResults(true) }} onBlur={showOff} className='searchControl__input' placeholder={'Search by title, author, publisher, translator, or ISBN'} disabled={disabled} value={query} onChange={(e) => setQuery(e.target.value)} />
      <div className='searchResults'>
        {showResults && hits.map((hit) =>
          <div className={`searchResultRow searchResult ${(isInList(hit._id) || current === hit._id) && 'searchResult-disabled'}`} key={hit._id}>
            <div className='searchImage-smaller'>
              {hit.cover && hit.cover['300'] && <img alt={hit.title} src={hit.cover['300'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} />}
            </div>
            <div>
              <div className='searchResult__pub'>{hit.publisherData.name}</div>
              <div className='searchResult__title'>{hit.title}</div>
              <div className='searchResult__authors'>{hit.authors && hit.authors.join(', ')}</div>
              <div className='searchResult__authors'>{hit.format}</div>
            </div>
            <div>
              <button className='buttonSecondary-small' onClick={() => {
                if (!isInList(hit._id) && current !== hit._id) {
                  addNew(sendData ? hit : hit._id);
                }
              }}>{text}</button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}