import React from 'react';
import './style.css';
import Select from 'react-select';

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'var(--theme-light)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: state.isFocused || state.isHovered ? 'var(--theme-deep-blue)' : 'var(--theme-dark-accent)',
    maxHeight: '30px',
    minHeight: '30px',
    color: 'var(--theme-off-black)'
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    color: 'var(--theme-off-black)',
    borderColor: 'none',
    fontFamily: 'NeueMontreal',
    fontSize: '14px'
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: 'var(--theme-dark-accent)'
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'var(--theme-main-backgrond)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '14px',
    fontFamily: 'NeueMontreal',
    fontWeight: state.isSelected ? '700' : '400',
    color: state.isSelected ? 'var(--theme-red)' : 'var(--theme-off-black)',
    backgroundColor: state.isFocused ? 'var(--theme-less-light)' : 'var(--theme-main-background)',
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    fontFamily: 'NeueMontreal',
    color: 'var(--theme-off-black)',
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: '0px 8px'
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    fontFamily: 'NeueMontreal',
    color: '#e4e7ed',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontFamily: 'NeueMontreal',
    fontSize: '14px',
    color: 'var(--theme-off-black)',
  }),
}


export default function ReactSelectWrapper({ value, change, options, disabled, placeholder = '' }) {
  const getValue = (v) => {
    return options.filter((option) => {
      return value === option.value;
    })
  }
  return (
    <Select
      value={getValue()}
      onChange={(v) => { change(v.value); }}
      options={options}
      styles={selectStyles}
      disabled={disabled}
    />
  )
}