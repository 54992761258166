import React, { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { cartState, modeState, messageState, sessionState, pageviewState, userState } from '../../atoms';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { canOrderProduct, returnPrice, isOnSale, prepareAuthorList, analyticsEvent, updatedCart, asaEvent, slugify, wishlistChange } from '../../Utils';
import './style.css';



const getFontSize = (title) => {
  let chars = title.length;
  let uppercase = /[A-Z]/g;
  let u = 0;
  let l = 0;
  for (let i = 0; i < chars; i++) {
    if (title.charAt(i).match(uppercase)) {
      u++;
    } else {
      l++;
    }
  }
  let score = l + (u * 1.2);
  if (score < 20) {
    return 24;
  } else if (score < 30) {
    return 21;
  } else if (score < 40) {
    return 18;
  } else if (score < 52) {
    return 16;
  } else {
    return 14;
  }
}

export default function ProductListItemSmall({ product, featured = false, order = null, context, alt = false }) {
  const mode = useRecoilValue(modeState);
  const [cart, setCart] = useRecoilState(cartState);
  const setMessage = useSetRecoilState(messageState);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);
  const user = useRecoilValue(userState);

  const navigate = useNavigate();

  const addToCart = () => {
    setCart(updatedCart(cart, product));
    setMessage({ type: 'success', label: 'Added to Cart', text: `<strong>${product.title}</strong> has been added to your cart!`, img: product.cover['300'], temp: false, cart: true });
    analyticsEvent('addToCart', { product: product._id, location: 'productList', mode: mode });
    asaEvent({
      session: session.id,
      category: 'cart',
      action: 'addToCart',
      label: product._id,
      locationOne: `${context.block}_${context.index}_${slugify(context.title)}`,
      locationTwo: 'productCard_small',
      locationThree: 'button',
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
      value: 1
    });
  }

  const pcEvent = (category, action, element) => {
    asaEvent({
      session: session.id,
      category: category,
      action: action,
      label: product._id,
      locationOne: 'homepage',
      locationTwo: `${context.block}_${context.index}_${context.title}`,
      locationThree: element,
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
    });
  }

  const image = useRef();

  useEffect(() => {
  }, [image]);

  useEffect(() => {
    getFontSize(product.title);
  }, [product.title])


  return (
    <div className={`productContainer productContainer_smaller`}>
      <div className={`productItem ${product.staffPick && 'staffPickProduct'}`}>
        {product.staffPick && <StaffPick pick={product.staffPick} />}
        <div className={`productItem__image ${alt && 'alt'}`}>
          {product.cover && <Link to={`/product/${product.slug}`} onClick={() => { pcEvent('recirc', 'click', 'productCover') }}><img src={product.cover['300'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} alt={product.title} /></Link>}
        </div>
        <div className='productItem__info'>
          <div className='productItem__left'>
            <div className='productItem__publisher'><Link to={`/publisher/${product.publisherData.slug}`} onClick={() => { pcEvent('recirc', 'click', 'publisherName') }}>{product.publisherData.name}</Link></div>
            <Link to={`/product/${product.slug}`} className='productItem__title productItem__title-small' onClick={() => { pcEvent('recirc', 'click', 'productTitle') }} style={{ fontSize: `${getFontSize(product.title)}px` }}>{product.title}</Link>
            <div className='productItem__authors'>{prepareAuthorList(product)}</div>
          </div>
          <div className='productItem__right'>

          </div>
        </div>
        <div className='productItem__bottom'>
          <div>
            <div className='productItemPrice'>
              <div className='productItemPrice__retail'>
                ${returnPrice(product, mode).toFixed(2)}
                {isOnSale(product, mode) && <span className='saleTag'><i className='fa-solid fa-tag'></i></span>}
              </div>
              {canOrderProduct(product, mode) && mode === 'wholesale' && <div className='productItemPrice__wholesale'>{product.prices.wholesaleDiscount}% off</div>}
            </div>
          </div>
          <div className='productItem__controls'>
            <button className='productSmallAddToCart' disabled={!canOrderProduct(product, mode)} onClick={addToCart}><i className='fa-solid fa-bag-shopping'></i> Add</button>
            <button className='productSmallWishlist' onClick={() => {
              asaEvent({
                session: session.id,
                category: 'wishlist',
                action: 'click',
                label: product._id,
                locationOne: '',
                locationTwo: '',
                locationThree: 'button',
                testgroup: session.group,
                pagetype: pageview.pagetype,
                pageview: pageview.id,
                campaign: session.campaign,
                value: 1
              });
              if (user.id) {
                wishlistChange(user.id, product._id, true);
                setMessage({ type: 'success', label: 'Added to Wishlist', text: `<strong>${product.title}</strong> has been added to your wishlist!`, temp: true })
              } else {
                navigate(`/login?wishlist=${product._id}`);
              }
            }}><i className='fa-solid fa-bookmark'></i></button>
          </div>
        </div>
      </div>
    </div>
  )
}


function StaffPick({ pick }) {
  const photos = {
    'Alexis': 'https://asterism-files.s3.us-east-1.amazonaws.com/ffe295f2bd48647c7f354d7cb024bc24/1734044094-600.jpg',
    'Miriam': 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/Miriam+Milena.jpg',
    'Ryan': 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/ryan.jpg',
    'Josh': 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/IMG_9861.jpg',
    'Phil': 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/phil.jpg',
    'Annabel': 'https://asterism-files.s3.us-east-1.amazonaws.com/ffe295f2bd48647c7f354d7cb024bc24/1734043929-600.jpg',
    'Jade': '',
    'Molli': 'https://asterism-files.s3.us-east-1.amazonaws.com/ffe295f2bd48647c7f354d7cb024bc24/1734044032-600.jpg'
  }

  return (<div className='staffPickContainer'>
    <div className='staffPickImg' style={{ background: `url(${photos[pick.person]})` }}></div>
    <div className='staffPickName'>{pick.person}'s Pick</div>
  </div>)
}