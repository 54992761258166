import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import ProductListItem from '../../components/ProductListItem';
import { RangeControl, PublisherControl, SelectWithSearchControl } from '../../components/InputControls';
import { makeRequest, Loading, firstFeaturedIndex, formatISODate } from '../../Utils';
import { cats } from '../../Defaults';
import moment from 'moment';
import './style.css';
import Loader from './Loader';
import GhostGrid from '../../components/GhostGrid';
import { exportProducts } from './export';
import { codes } from '../PubProductEdit/bisac';
import { useRecoilValue } from 'recoil';
import { userState } from '../../atoms';

export default function BooksAll({ mode }) {
  const [products, setProducts] = useState([]);
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(false);
  const [featured, setFeatured] = useState(-1);
  const [minYear, setMinYear] = useState(1970);
  const [maxYear, setMaxYear] = useState(parseInt(moment().format('YYYY')));
  const [publisher, setPublisher] = useState('');
  const [minPages, setMinPages] = useState(1);
  const [maxPages, setMaxPages] = useState(2000);
  const [formats, setFormats] = useState([]);
  const [bisac, setBisac] = useState('');
  const [categories, setCategories] = useState([]);
  const [hasPreview, setHasPreview] = useState(false);
  const [inStockNow, setInStockNow] = useState(false);
  const [onSale, setOnSale] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastOne, setLastOne] = useState(false);
  const [used, setUsed] = useState(false);
  const [page, setPage] = useState(0);
  let [searchParams, setSearchParams] = useSearchParams();
  const [filtersOpen, setFiltersOpen] = useState(false);

  const per = 20;

  document.title = `Browse All${mode === 'new' ? ' New ' : mode === 'upcoming' ? ' Upcoming ' : mode === 'ebooks' ? 'e' : ' '}Books | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Browse and search through our entire product catalog of small press books!`);

  useEffect(() => {
    let featuredIndex = firstFeaturedIndex(products);
    setFeatured(featuredIndex);
  }, [products])

  const allFormats = [{
    value: 'Paperback',
    label: 'Paperback'
  },
  {
    value: 'Chapbook',
    label: 'Chapbook'
  },
  {
    value: 'Pamphlet',
    label: 'Pamphlet'
  },
  {
    value: 'Hardcover',
    label: 'Hardcover'
  },
  {
    value: 'ebookPDF',
    label: 'Ebook (PDF)'
  },
  {
    value: 'ebookEPUB',
    label: 'Ebook (ePub)'
  },
  {
    value: 'ebookMulti',
    label: 'Ebook (multiple)'
  },
  {
    value: 'record',
    label: 'LP'
  },
  {
    value: 'other',
    label: 'Other'
  }];

  const debounceDelay = 750;
  const debounceTimer = useRef(0);
  const controller = useRef();

  const location = useLocation();

  useEffect(() => {
    setPage(0);
    setHasMore(false);
  }, []);

  const getNew = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return formatISODate(tomorrow);
  }

  const getUpcoming = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return formatISODate(tomorrow);
  }

  const getProducts = async (more = false) => {
    clearTimeout(debounceTimer.current);
    controller.current?.abort();
    controller.current = new AbortController();
    setLoading(more ? false : true);
    debounceTimer.current = setTimeout(async () => {
      let urlAppend = window.location.search && more ? `&page=${page}` : more ? `?page=${page}` : '';
      if (mode === 'new') {
        let newDate = getNew();
        urlAppend += urlAppend ? `&beforeDate=${newDate}` : `?beforeDate=${newDate}`;
      } else if (mode === 'upcoming') {
        let upcomingDate = getUpcoming();
        urlAppend += urlAppend ? `&afterDate=${upcomingDate}&ascending=true` : `?afterDate=${upcomingDate}&ascending=true`;
      }
      let data = await makeRequest(`all-products${window.location.search}${urlAppend}`, 'GET');
      setProducts(more ? products.concat(data.docs) : data.docs); // Either add new stuff to the array or set the new products
      setPage(page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }, debounceDelay)
  }

  useEffect(() => {
    setHasMore(page * per === products.length);
  }, [products, page]);

  useEffect(() => {
    if (mode === 'ebooks') {
      setFormats(['ebookPDF', 'ebookEPUB', 'ebookMulti']);
    }
  }, []);

  const toggleCat = (value) => {
    let newCats = [...categories];
    let i = categories.indexOf(value);
    if (i !== -1) {
      newCats.splice(i, 1);
    } else {
      newCats.push(value);
    }
    setCategories(newCats);
  }

  const toggleFormat = (value) => {
    let newFormats = [...formats];
    let i = formats.indexOf(value);
    if (i !== -1) {
      newFormats.splice(i, 1);
    } else {
      newFormats.push(value);
    }
    setFormats(newFormats)
  }

  useEffect(() => {
    setPage(0);
  }, [location])

  useEffect(() => {
    getProducts();
  }, [searchParams, location]);

  useEffect(() => {
    if (page === 0) {
      getProducts();
    }
  }, [page])

  useEffect(() => {
    if (searchParams.get('maxPages')) {
      setMaxPages(parseInt(searchParams.get('maxPages')))
    }
    if (searchParams.get('minPages')) {
      setMinPages(parseInt(searchParams.get('minPages')))
    }
    if (searchParams.get('maxYear')) {
      setMaxYear(parseInt(searchParams.get('maxYear')))
    }
    if (searchParams.get('minYear')) {
      setMinYear(parseInt(searchParams.get('minYear')))
    }
    if (searchParams.get('pub')) {
      setPublisher(searchParams.get('pub'));
    }
    if (searchParams.get('formats')) {
      setFormats(searchParams.get('formats').split(','))
    }
    if (searchParams.get('bisac')) {
      setBisac(searchParams.get('bisac'))
    }
    if (searchParams.get('hasPreview')) {
      setHasPreview(searchParams.get('hasPreview' == 'true'))
    }
    if (searchParams.get('inStockNow')) {
      setInStockNow(searchParams.get('inStockNow' == 'true'))
    }
    if (searchParams.get('categories')) {
      setCategories(searchParams.get('categories').split(','))
    }
    if (searchParams.get('lastOne')) {
      setHasPreview(searchParams.get('lastOne' == 'true'))
    }
    if (searchParams.get('hasUsed')) {
      setUsed(true)
    }
    if (searchParams.get('onSale')) {
      setOnSale(true)
    }
  }, []);

  useEffect(() => {
    if (publisher) {
      searchParams.set('pub', publisher)
    } else {
      searchParams.delete('pub');
    }
    setSearchParams(searchParams);
  }, [publisher]);

  useEffect(() => {
    if (!onSale) {
      searchParams.delete('onSale');
    } else {
      searchParams.set('onSale', 'true');
    }
    setSearchParams(searchParams);
  }, [onSale])

  useEffect(() => {
    if (!used) {
      searchParams.delete('hasUsed');
    } else {
      searchParams.set('hasUsed', 'true');
    }
    setSearchParams(searchParams);
  }, [used])

  useEffect(() => {
    if (!bisac) {
      searchParams.delete('bisac');
    } else {
      searchParams.set('bisac', bisac);
    }
    setSearchParams(searchParams);
  }, [bisac])

  useEffect(() => {
    if (inStockNow) {
      searchParams.set('inStockNow', true)
    } else {
      searchParams.delete('inStockNow');
    }
    setSearchParams(searchParams);
  }, [inStockNow]);

  useEffect(() => {
    if (hasPreview) {
      searchParams.set('hasPreview', true)
    } else {
      searchParams.delete('hasPreview');
    }
    setSearchParams(searchParams);
  }, [hasPreview]);

  useEffect(() => {
    if (lastOne) {
      searchParams.set('lastOne', true)
    } else {
      searchParams.delete('lastOne');
    }
    setSearchParams(searchParams);
  }, [lastOne]);

  useEffect(() => {
    if (formats && formats.length > 0) {
      searchParams.set('formats', formats.join(','))
    } else {
      searchParams.delete('formats');
    }
    setSearchParams(searchParams);
  }, [formats]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      searchParams.set('categories', categories.join(','))
    } else {
      searchParams.delete('categories');
    }
    setSearchParams(searchParams);
  }, [categories]);

  useEffect(() => {
    if (minYear && minYear !== 1970) {
      searchParams.set('minYear', minYear)
    } else {
      searchParams.delete('minYear');
    }
    setSearchParams(searchParams);
  }, [minYear]);

  useEffect(() => {
    if (maxYear && maxYear !== 2025) {
      searchParams.set('maxYear', maxYear)
    } else {
      searchParams.delete('maxYear');
    }
    setSearchParams(searchParams);
  }, [maxYear]);

  useEffect(() => {
    if (minPages && minPages > 1) {
      searchParams.set('minPages', minPages)
    } else {
      searchParams.delete('minPages');
    }
    setSearchParams(searchParams);
  }, [minPages]);

  useEffect(() => {
    if (maxPages && maxPages < 2000) {
      searchParams.set('maxPages', maxPages)
    } else {
      searchParams.delete('maxPages');
    }
    setSearchParams(searchParams);
  }, [maxPages]);

  const exportView = () => {
    let data = exportProducts(products);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
    hiddenElement.target = '_blank';
    hiddenElement.download = `asterism-exported-view-${moment().format('DD-MM-YYYY')}.csv`;
    hiddenElement.click();
  }

  return (
    <div>
      <div className='flex-layout pageHeadContainer roomAboveMedium roomBelowMedium'>
        <h1 className='page-header'>
          {`All${mode === 'new' ? ' New ' : mode === 'upcoming' ? ' Upcoming ' : mode === 'ebooks' ? 'e' : ' '}Books`}
        </h1>
        <button onClick={() => { setFiltersOpen(true) }} className='buttonSecondary buttonSecondarySmall filterMobile'><i className='fa-solid fa-filter'></i> Filter</button>
      </div>
      <div className='filteredGrid'>
        <div className={`filterPanel ${filtersOpen && 'filterPanelOpen'}`}>
          <div className='roomBelowLarge filterSticky'><button onClick={() => { setFiltersOpen(false) }} className='buttonSecondary buttonSecondarySmall filterMobile'>Close Filters</button></div>
          <div className='sidebarFilter'>
            <PublisherControl
              label='Publisher'
              value={publisher}
              change={setPublisher}
            /></div>

          {user.admin && <div className='sidebarFilter'>
            <SelectWithSearchControl
              value={bisac}
              change={setBisac}
              label={'Primary BISAC Subject Code'}
              help={''}
              options={codes.map((code) => {
                return {
                  label: code['Literal'],
                  value: code['Code'],
                }
              })}
            />
          </div>}
          <div className='sidebarFilter'>
            <div className='pseudoLabel'>Formats</div>
            <div className='catList'>
              {allFormats.map((format) => {
                if (mode !== 'ebooks' || format.value.includes('ebook')) {
                  return (
                    <div className='catListItem'>
                      <button className={`catSelector ${formats.indexOf(format.value) !== -1 && 'catSelected'}`} onClick={() => {
                        toggleFormat(format.value);
                      }}></button>
                      <div className='catSelectorLabel'>{format.label}</div>
                    </div>
                  )
                }
              }
              )}
            </div>
          </div>
          <div className='sidebarFilter'>
            <div className='pseudoLabel'>Categories</div>
            <div className='catList'>
              {cats.map((cat) =>
                <div className='catListItem'>
                  <button className={`catSelector ${categories.indexOf(cat.value) !== -1 && 'catSelected'}`} onClick={() => {
                    toggleCat(cat.value);
                  }}></button>
                  <div className='catSelectorLabel'>{cat.label}</div>
                </div>
              )}
            </div>
          </div>
          {mode === 'default' && <div className='sidebarFilter'>
            <RangeControl
              label='Year Published'
              value={[minYear, maxYear]}
              min={1970}
              max={2030}
              change={(value) => {
                setMinYear(value[0]);
                setMaxYear(value[1]);
              }}
            />
          </div>}
          <div className='sidebarFilter'><RangeControl
            label='Pagecount'
            value={[minPages, maxPages]}
            min={1}
            max={2000}
            step={10}
            change={(value) => {
              setMinPages(value[0]);
              setMaxPages(value[1]);
            }}
          /></div>
          <div className='sidebarFilter'>
            <div className='pseudoLabel'>Quick Filters</div>
            <div className='catList'>
              <div className='catListItem'>
                <button className={`catSelector ${onSale && 'catSelected'}`} onClick={() => {
                  setOnSale(!onSale);
                }}></button>
                <div className='catSelectorLabel'>On sale</div>
              </div>
              <div className='catListItem'>
                <button className={`catSelector ${inStockNow && 'catSelected'}`} onClick={() => {
                  setInStockNow(!inStockNow);
                }}></button>
                <div className='catSelectorLabel'>In-stock now</div>
              </div>
              <div className='catListItem'>
                <button className={`catSelector ${used && 'catSelected'}`} onClick={() => {
                  setUsed(!used);
                }}></button>
                <div className='catSelectorLabel'>Available used</div>
              </div>
              <div className='catListItem'>
                <button className={`catSelector ${hasPreview && 'catSelected'}`} onClick={() => {
                  setHasPreview(!hasPreview);
                }}></button>
                <div className='catSelectorLabel'>Title has preview</div>
              </div>
              <div className='catListItem'>
                <button className={`catSelector ${lastOne && 'catSelected'}`} onClick={() => {
                  setLastOne(!lastOne);
                }}></button>
                <div className='catSelectorLabel'>One Copy Left</div>
              </div>
            </div>
          </div>

          <div>
            <button className='buttonSecondary' onClick={exportView}>Export View</button>
          </div>

        </div>
        <div>
          <div className='lowerGrid'>
            {loading && <GhostGrid />}
            {!loading && products.length == 0 && <div>No products matched your request.</div>}
            {products.map((product, index) =>
              <ProductListItem used={used} key={product._id} product={product} featured={index === featured} context='allBooks' />
            )}
          </div>
          <Loader fetching={loading} getMore={getProducts} hasMore={hasMore} page={page} />
        </div>
      </div>
    </div>
  )
}
