import React, { useEffect, useRef, useState } from 'react';
import { TextControl, CountryControl, SelectSearch } from '../InputControls';
import { updateObject } from '../../Utils';
import { provinces, states } from './lists';

export default function AddressForm({ address, setAddress, uploading = false, label = 'Shipping', attn = false }) {

  /**
 * 
 */
  const isAmericanPostalCode = (code) => {
    const countryRegEx = /^[0-9][0-9][0-9][0-9][0-9]$|^[0-9][0-9][0-9][0-9][0-9][-][0-9][0-9][0-9][0-9]$/gm;
    return !code || countryRegEx.test(code);
  }

  const prevCountry = useRef();

  useEffect(() => {
    if (prevCountry?.current && prevCountry.current !== address.country) {
      updateObject(address, 'state', '', setAddress);
    }
    prevCountry.current = address.country;
  }, [address.country])

  return (
    <div>
      <div className=''>
        <TextControl
          value={address.name}
          name={'Name'}
          change={(value) => { updateObject(address, 'name', value, setAddress) }}
          label={'Name'}
          valid={false}
          type='text'
          bypass={true}
          disabled={uploading}
        />
        <TextControl
          value={address.company ? address.company : ''}
          name={'Company'}
          change={(value) => { updateObject(address, 'company', value, setAddress) }}
          label={'Company'}
          valid={false}
          bypass={true}
          type='text'
          disabled={uploading}
        />
        <TextControl
          value={address.lineOne}
          name={'Address Line One'}
          change={(value) => { updateObject(address, 'lineOne', value, setAddress) }}
          label={'Address Line One'}
          valid={false}
          autocomplete={'address-line1'}
          type='text'
          bypass={true}
          disabled={uploading}
        />
        <TextControl
          value={address.lineTwo}
          name={'Address Line Two'}
          change={(value) => { updateObject(address, 'lineTwo', value, setAddress) }}
          label={'Address Line Two'}
          autocomplete={'address-line2'}
          valid={false}
          type='text'
          bypass={true}
          disabled={uploading}
        />
      </div>
      <div className='gridLayout gridColumns2 gridGapMedium'>
        <TextControl
          name={'Address City'}
          value={address.city}
          autocomplete={'address-level2'}
          change={(value) => { updateObject(address, 'city', value, setAddress) }}
          label={'City'}
          valid={false}
          type='text'
          bypass={true}
          disabled={uploading}
        />

        {(address.country !== 'US' && address.country !== 'CA') ? <TextControl
          autocomplete={'address-state'}
          value={address.state ? address.state : ''}
          change={(value) => { updateObject(address, 'state', value, setAddress) }}
          label={'State/Region'}
          valid={false}
          type='text'
          bypass={true}
          disabled={uploading}
        /> :
          <SelectSearch
            label={address.country === 'US' ? 'State' : 'Province'}
            value={address.state}
            change={(value) => { updateObject(address, 'state', value, setAddress) }}
            disabled={uploading}
            options={address.country === 'US' ? states : provinces}
          />}

        <TextControl
          value={address.postalCode}
          name={'Address Postal Code'}
          change={(value) => { updateObject(address, 'postalCode', value, setAddress) }}
          label={'Postal Code'}
          autocomplete={'postal_code'}
          valid={false}
          type='text'
          bypass={true}
          disabled={uploading}
        />


        <CountryControl
          value={address.country}
          label={'Country'}
          change={(value) => { updateObject(address, 'country', value, setAddress) }}
          help={
            !isAmericanPostalCode(address.postalCode) && address.country === 'US' ? 'The postal code you entered doesn\'t appear to be a US postal code.' : ''
          }
        />
      </div>

    </div>
  )
}